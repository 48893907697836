<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Permissions for {{event.name}}</v-card-title>

      <div >
        <v-card-text>      
          <p>
            By default only you have access to your event. You can optionally grant access to others as well. To do this you need to get their profile ID. They can easily find that by going to the 'Edit Profile' page and then scroll to the bottom of the page to copy their profile ID. 
          </p>
          <p>
            Please note that the persons you permit access will have the exact same permissions as you have and can therefor also invite others.
          </p>

          <v-alert v-if="event.org" type="info">
            <p>This event is part of the {{ event.org.name }} organization. Any permissions set on organization level will automatically apply to this event.</p>
          </v-alert>

        </v-card-text>
        <v-alert v-if="!eventUtil.isProPlan()" type="info" tile>
          <div class="d-flex flex-row align-content-center">
            <p class="grow mb-0"><router-link class="white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade to a PRO plan</router-link> to add co-admins.</p>
            <v-btn class="shrink white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade</v-btn>
          </div>
        </v-alert>

        <v-data-table
          :headers="headers"
          :items="permissions"
          sort-by="name"
          :items-per-page="25"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="white" class="mx-4">
              <v-toolbar-title class="ml-0 subtitle">Permissions</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn v-if="true" color="accent" class="mb-2 me-2" :disabled="!eventUtil.isProPlan()" @click="showInviteUserDialog=true">Invite</v-btn>
              <v-btn outlined color="accent" class="mb-2" :disabled="!eventUtil.isProPlan()" @click="showAddUserDialog=true">Add User</v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.roles="{ item }">
            {{ item.roles.map(x => $helpers.displayText(siteData.roles, x)).join(", ") }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-4" @click="editPermissions(item)">
              fa-pencil
            </v-icon>
            <v-icon small class="mr-4" @click="deletePermissions(item.name, item.id)">
              fa-trash
            </v-icon>
          </template>
          <template v-slot:no-data>
            <i>No extra permissions granted, only you will have access to this challenge.</i>
          </template>
        </v-data-table>

        <v-data-table
          v-if="event.admin_invites && event.admin_invites.length"
          :headers="[{value: 'email', text: 'Email'}, {value: 'expired', text: 'Status'}, {value:'roles', text: 'Roles'}, {value:'actions', text: ''}]"
          :items="event.admin_invites"
          sort-by="email"
          :items-per-page="25"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="white" class="mx-4">
              <v-toolbar-title class="ml-0 subtitle">Invites</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.email="{ item }">
            {{ item.email }}
          </template>
          <template v-slot:item.expired="{ item }">
            <span v-if="item.expired">Expired</span>
            <span v-else>Active</span>
          </template>
          <template v-slot:item.roles="{ item }">
            {{ item.roles.map(x => $helpers.displayText(siteData.roles, x)).join(", ") }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn text small color="accent" class="mr-4" @click="resendInvite(item)">
              <v-icon small class="me-1">fa-paper-plane</v-icon>
              Re-send
            </v-btn>
          </template>
          <template v-slot:no-data>
            <i>No extra permissions granted, only you will have access to this challenge.</i>
          </template>
        </v-data-table>

      </div>      
    </v-card>

    <v-dialog v-model="showAddUserDialog">
      <v-card>
        <v-card-title>Add an admin</v-card-title>
        <v-card-text>
          Add an admin user to this event. This user will have access to (certain parts of) the backend of this event. Only add users you trust.
        </v-card-text>
        <v-card-text>
          You need to have the Profile ID of the user you want to add. The user can find this (and send to you) from the Edit Profile screen in the app or website when they are logged in.
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="profileId" 
            label="Profile ID"
            persistent-hint
            hint="The user can find this on their Edit Profile screen"
            required
            autofocus
            />
        </v-card-text>
        <v-card-text>
          Select one or more roles for the user:
          <v-checkbox 
            v-for="(item, index) in siteData.roles" 
            multiple 
            v-model="selectedRoles" 
            :key="index" 
            :value="item.type" 
            :label="item.text" 
            :disabled="!eventUtil.isEnterprisePlan()"
            :hint="item.description"
            persistent-hint
            />
          <v-alert v-if="!eventUtil.isEnterprisePlan()" type="info" outlined class="mt-4">Upgrade to an Enterprise Plan to be able to set more fine-grained permissions.</v-alert>
        </v-card-text>
        
        <v-card-text>
          <v-btn color="primary" :disabled="!profileId || !selectedRoles || !selectedRoles.length" @click="addPermissions">Save</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showInviteUserDialog">
      <v-card>
        <v-card-title>Invite an co-admin</v-card-title>
        <v-card-text>
          Invite a co-admin user to this event. This user will have access to (certain parts of) the backend of this event. Only invite users you trust.
        </v-card-text>
        
        <v-card-text>
          <v-text-field
            v-model="email" 
            label="Email"
            persistent-hint
            hint="The email of the user you want to invite"
            required
            autofocus
            />
        </v-card-text>
        <v-card-text>
          Select one or more roles for the user:
          <v-checkbox 
            v-for="(item, index) in siteData.roles" 
            multiple 
            v-model="selectedRoles" 
            :key="index" 
            :value="item.type" 
            :label="item.text" 
            :disabled="!eventUtil.isEnterprisePlan()"
            :hint="item.description"
            persistent-hint
            />
          <v-alert v-if="!eventUtil.isEnterprisePlan()" type="info" outlined class="mt-4">Upgrade to an Enterprise Plan to be able to set more fine-grained permissions.</v-alert>
        </v-card-text>
        
        <v-card-text>
          <v-btn color="primary" :disabled="!email || !selectedRoles || !selectedRoles.length" @click="sendInvite">Send Invite</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import Header from './_Header.vue'
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Permissions",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      showInviteUserDialog: false,
      showAddUserDialog: false,
      event: null,
      permissions: [],
      profileId: null,
      email: null,
      selectedRoles: ["FULL_ACCESS"],
      headers: [
        { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name',},
        { text: 'Roles', align: 'start', sortable: true, filterable: true, value: 'roles',},
        { text: 'Actions', align: 'start', sortable: false, filterable: false, value: 'actions',},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.permissions = (await eventManagerService.getPermissions(id)).data.data || [];
      console.log(this.permissions);
    },

    async resendInvite(invite) {
      this.email = invite.email;
      this.selectedRoles = invite.roles;
      this.sendInvite();
    },
    async sendInvite() {
      if (this.email && this.selectedRoles && this.selectedRoles.length) {
        const response = (await eventManagerService.inviteAdmin(this.event.id, this.email, this.selectedRoles)).data;
        await this.getProfile();
        this.showInviteUserDialog = false;
        this.$helpers.toastResponse(this, response, 'Invite successfully sent.');
      }
    },

    async addPermissions() {
      if (this.profileId && this.selectedRoles && this.selectedRoles.length) {
        const response = (await eventManagerService.addPermissions(this.event.id, this.profileId, this.selectedRoles)).data;
        await this.getProfile();
        this.showAddUserDialog = false;
        this.$helpers.toastResponse(this, response, 'Permission added successfully.');
      }
    },

    async editPermissions(permissions) {
      this.profileId = permissions.id;
      this.selectedRoles = permissions.roles;
      this.showAddUserDialog = true;
    },

    async deletePermissions(name, profileId) {
      if (confirm(`Are you sure you want to remove access by ${name}?`)) {
        const response = (await eventManagerService.deletePermissions(this.event.id, profileId)).data;
        await this.getProfile();
        this.$helpers.toastResponse(this, response, 'Permission revoked successfully.');
      }
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Permissions', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

